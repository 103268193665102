import { Skeleton } from "antd"
import { Table } from "./tables/Table"
import { defaultTableConfig } from "../libs/config"

export function BatchDetail({ data }) {

      const columns = [
            {
                  name: 'Time',
                  key: 'started'
            },
            {
                  name: 'Type',
                  key: 'type'
            },
            {
                  name: 'Cardholder',
                  key: 'cardholder'
            },
            {
                  name: 'Amount',
                  key: 'amount'
            },
            {
                  name: 'Acturis',
                  key: 'acturis'
            }
      ]


      const customTableConfig = {
            columns,
            search: {
                  active: false
            },
            export: {
                  active: false
            },
            datepicker: {
                  active: false
            },
            pagination: {
                  defaultPageSize: 100,
                  showSizeChanger: true,
                  pageSizeOptions: [ '100', '500', '1000', '10000' ]
            },
      }

      if (data.length > 0) {

            return (<Table
                  data={data}
                  config={{ ...defaultTableConfig, ...customTableConfig }}
            />)

      }

      else {

            return (<Skeleton active />)

      }

}
