import { useState, useContext } from 'react'
import { Button, Drawer, Divider } from 'antd'
import { FaUserCircle } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'
import { MyContext } from '../App'

export function UserMenu() {

      const navigate = useNavigate()

      const { AppData, UserData, setUserData } = useContext(MyContext)

      const [ open, setOpen ] = useState(false)
      const showDrawer = () => {
            setOpen(true)
      }
      const onClose = () => {
            setOpen(false)
      }

      function logout() {
            setUserData({ loggedIn: false })
            navigate('/')
      }

      return (

            <>
                  <FaUserCircle className="clickable" onClick={showDrawer} style={{ marginLeft: '25px', color: 'rgba(0,0,0,0.6)', fontSize: '30px', marginTop: '10px' }} />

                  <Drawer title="PROFILE" placement="right" onClose={onClose} open={open}>

                        <p>Name: {UserData.first_name} {UserData.last_name}</p>
                        <p>Email: {UserData.username}</p>
                        <p>Role: {UserData.role}</p>
                        <p>Access Level: {UserData.accessLevel}</p>

                        <Divider />

                        <p>App Version: {AppData.version}</p>

                        <Divider />

                        <div className="menu-link"><Button type="link" onClick={logout}>Logout</Button></div>

                  </Drawer>
            </>

      )

}