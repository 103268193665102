import { FiUploadCloud, FiDownloadCloud } from 'react-icons/fi'
import { Table, Tag, Skeleton } from 'antd'
import dayjs from "dayjs"

export function ActurisAllErrorsTable({ data }) {

      const columns = [
            {
                  title: 'Record Created',
                  dataIndex: 'created',
                  key: 'created',
                  render: (text, record) => (
                        <span>{dayjs(record.created).format("HH:mm DD MMM YYYY")} </span>
                  )
            },
            {
                  title: 'Information',
                  key: 'event_type',
                  render: (item) => {
                        let label = ''
                        if (item.event_type === "C") { label = 'CANCELLATION' }
                        else if (item.event_type === "M") { label = 'ADJUSTMENT' }
                        else if (item.event_type === "N") { label = 'NEW BUSINESS' }
                        else { label = 'UNKNOWN' }
                        if (item?.policy_number) {
                              const brand = item.policy_number.includes("BG") ? "BG" : "FQ"
                              const color = item.policy_number.includes("BG") ? "green" : "blue"
                              return (<><Tag color={color}>{brand}</Tag><Tag>{label}</Tag></>)
                        }

                  }
            },
            {
                  title: 'Policy Number',
                  dataIndex: 'policy_number',
                  key: 'policy_number'
            },
            {
                  title: 'Insured',
                  dataIndex: 'legal_entity_name',
                  key: 'legal_entity_name'
            },
            {
                  title: 'Request',
                  key: 'request',
                  render: (item) => {
                        return (<FiUploadCloud className="clickable" onClick={(e) => alert(item.request)} />)
                  }
            },
            {
                  title: 'Response',
                  key: 'response',
                  render: (item) => {
                        return (<FiDownloadCloud className="clickable" onClick={(e) => alert(item.response)} />)
                  }
            }
      ]

      if (data.length > 0) {

            return (

                  <>

                        <Table rowKey="policy_number" style={{ marginTop: '25px' }} columns={columns} dataSource={data} pagination={{ defaultPageSize: 500, showSizeChanger: true, pageSizeOptions: [ '25', '50', '100', '500', '1000', '10000' ] }} />

                  </>

            )

      } else {

            return (<Skeleton active />)

      }



}

