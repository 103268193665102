import { Header } from "../Header"
import { DashAdmin } from "../DashAdmin"

export function Home() {

      return (

            <>
                  <Header />
                  <DashAdmin />
            </>

      )


}
