import { MyContext } from '../App'
import { useState, useContext } from 'react'
import { useSearchParams } from "react-router-dom"
import { Form, Input, Button, DatePicker, message, Select } from "antd"
import axios from "axios"
import dayjs from "dayjs"

export function Canx({ updatePolicy, data }) {

      const { AppData } = useContext(MyContext)
      const { UserData } = useContext(MyContext)

      const [ loading, setLoading ] = useState(false)
      const [ btnText, setBtnText ] = useState('Cancel this policy')
      const [ startDate ] = useState(dayjs(data.start_date).format("YYYY-MM-DD"))
      const [ effectiveDate, setEffectiveDate ] = useState(null)
      const [ canxReason, setCanxReason ] = useState(null)
      const [ isCancelled, setIsCancelled ] = useState(false)

      const [ searchParams ] = useSearchParams()
      const policy_number = searchParams.get("id")

      async function onFinish(values) {

            setLoading(true)
            setBtnText('Please wait ')

            const data = {
                  id: policy_number,
                  effective_date: dayjs(effectiveDate).format("YYYY-MM-DD"),
                  return_premium: parseFloat(values.return_premium),
                  return_fee: parseFloat(values.return_fee),
                  canx_reason: parseInt(canxReason),
                  user: UserData.id
            }

            console.log('Success', JSON.stringify(data))

            let result = await axios.post(`${AppData.api.base}/policy/cancel`, data)
                  .catch(function (error) {
                        console.log(error)
                        message.error('Something went wrong!')
                        setLoading(false)
                        setBtnText('Cancel this policy')

                  })

            console.log(JSON.stringify(result.data))

            setLoading(false)

            if (result.data.status === "success") {
                  message.success(`${policy_number} cancelled successfully`)
                  setIsCancelled(true)
                  updatePolicy()

            } else {
                  setBtnText('Cancel this policy')
                  message.error(result.data.result)
            }

      }

      const onChangeDatepicker = (date, dateString) => {
            setEffectiveDate(dayjs(dateString).format("YYYY-MM-DD"))
      }

      const disabledDate = (current) => {
            // Can not select days before today and today
            return current && current < dayjs(startDate)
      }

      const onSelectChange = (value) => {
            console.log(`selected canx reason ${value}`)
            setCanxReason(value)
      }

      if (isCancelled) {

            return (<p>This policy has been cancelled</p>)

      }

      else {

            return (
                  <div >
                        <Form
                              style={{ minWidth: "400px" }}
                              name="basic"
                              layout="vertical"
                              initialValues={{
                                    remember: true
                              }}
                              onFinish={onFinish}
                              autoComplete="off"
                        >

                              <Form.Item
                                    label="Effective cancellation date"
                                    name="effective_date"
                                    rules={[
                                          { required: true, message: 'Please select the cancellation date' }
                                    ]}
                              >
                                    <DatePicker style={{ minWidth: "451px" }} onChange={onChangeDatepicker} disabledDate={disabledDate} />
                              </Form.Item>

                              <Form.Item
                                    label="Return Premium - Inclusive of IPT"
                                    name="return_premium"
                                    validateFirst={true}
                                    rules={[
                                          { required: true, message: 'Please input a value' },
                                          {
                                                validator(_, value) {

                                                      if (value >= 0.00) {

                                                            if (parseFloat(value) <= data.gross_premium || UserData.accessLevel > 3) {

                                                                  return Promise.resolve()
                                                            }

                                                            return Promise.reject(new Error('You cannot refund more than the original premium'))
                                                      }

                                                      return Promise.reject(new Error('The value should be >= 0.00'))
                                                }
                                          }
                                    ]}
                              >
                                    <Input placeholder="A number, always greater or equal to 0.00" />
                              </Form.Item>

                              <Form.Item
                                    label="Return Fee - eg Original fee £50.00 & Canx fee £22.50 = Return fee £27.50 "
                                    name="return_fee"
                                    validateFirst={true}
                                    rules={[
                                          { required: true, message: 'Please input a value' },
                                          {
                                                validator(_, value) {

                                                      if (value >= 0.00) {

                                                            return Promise.resolve()
                                                      }

                                                      return Promise.reject(new Error('The value should be >= 0.00'))
                                                }
                                          }
                                    ]}
                              >
                                    <Input placeholder="A number, always greater or equal to 0.00" />
                              </Form.Item>

                              <Form.Item
                                    label="Reason for cancellation"
                                    name="canx_reason"
                                    rules={[
                                          { required: true, message: 'Please provide a reason for cancellation!' }
                                    ]}
                              >
                                    <Select
                                          showSearch
                                          placeholder="Select a reason"
                                          optionFilterProp="children"
                                          onChange={onSelectChange}
                                          allowClear
                                          filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                          }
                                          options={[
                                                {
                                                      value: '1',
                                                      label: 'No longer required',
                                                },
                                                {
                                                      value: '2',
                                                      label: 'CBPF : Default payment',
                                                },
                                                {
                                                      value: '3',
                                                      label: 'CBPF : Failed ID&V',
                                                },
                                                {
                                                      value: '4',
                                                      label: 'Incorrect policy. Replacement with us',
                                                },
                                                {
                                                      value: '5',
                                                      label: 'Incorrect policy. Replacement with third party',
                                                },
                                                {
                                                      value: '6',
                                                      label: 'Undisclosed claim',
                                                },
                                                {
                                                      value: '7',
                                                      label: 'Other',
                                                }
                                          ]}
                                    />
                              </Form.Item>

                              <Form.Item>
                                    <Button type="primary" className="btn-canx" htmlType="submit" style={{ minWidth: "400px", backgroundColor: "#FF287F" }} loading={loading}>
                                          {btnText}
                                    </Button>
                              </Form.Item>
                        </Form>
                  </div>
            )

      }

}


