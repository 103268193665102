import { Row, Col } from "antd"
import { useNavigate } from 'react-router-dom'
import { TiFlashOutline } from "react-icons/ti"
import { NavMenu } from "./NavMenu"
import { UserMenu } from "./UserMenu"
import { Search } from "./Search"

export function Header() {

      const navigate = useNavigate()

      return (

            <div id="header">

                  <Row justify="center">

                        <Col span={23}>

                              <Row>

                                    <Col span={12}>
                                          <TiFlashOutline className="clickable" onClick={(e) => navigate('/')} style={{ color: '#E41882', fontSize: '50px' }} />
                                    </Col>

                                    <Col span={12} style={{ textAlign: 'right' }}>

                                          <Search />

                                          <NavMenu />

                                          <UserMenu />

                                    </Col>

                              </Row>

                        </Col>

                  </Row>

            </div>
      )

}