import { MyContext } from '../App'
import { useState, useContext } from 'react'
import { useSearchParams } from "react-router-dom"
import { Form, Input, Button, DatePicker, Divider, Select, message, Tooltip, Radio } from "antd"
import { round } from "../libs/helper"
import { InfoCircleOutlined } from '@ant-design/icons'
import axios from "axios"
import dayjs from "dayjs"

export function Mta({ updatePolicy, data, entity }) {

      const { AppData, UserData } = useContext(MyContext)

      // console.log(data)
      const [ loading, setLoading ] = useState(false)
      const [ btnText, setBtnText ] = useState('Process Mid-Term Adjustment')
      const [ effectiveDate, setEffectiveDate ] = useState(dayjs().format("YYYY-MM-DD"))
      // const [ isAdjusted, setIsAdjusted ] = useState(false)
      const [ startDate ] = useState(dayjs(data.start_date).format("YYYY-MM-DD"))
      const [ paymentType, setPaymentType ] = useState(data.payment_type.toLowerCase() === "f" ? "OPAYO" : "CBPF")
      const [ paymentRef, setPaymentRef ] = useState(data.payment_type.toLowerCase() !== "f" ? data.cbpf_ref : '')
      const [ paymentRefPlaceholder, setPaymentRefPlaceholder ] = useState(data.payment_type.toLowerCase() === "f" ? 'eg Tboxguard-230927112759-128' : '')

      const [ searchParams ] = useSearchParams()
      const policy_number = searchParams.get("id")

      async function onFinish(values) {

            setLoading(true)
            setBtnText('Please wait ...')

            // Prepare mta object for API

            // First convert legal_entity_type .. messy
            let le = ''
            if (policy_number.includes("BG")) {
                  switch (values.legal_entity) {
                        case "Limited Company": le = "LC"; break
                        case "Sole Trader": le = "ST"; break
                        case "Limited Liability Partnership": le = "LL"; break
                        case "Partnership": le = "PA"; break
                        default: le = "Unknown"; break
                  }
            }
            else {
                  le = values.legal_entity
            }

            // Main object
            const data = {
                  id: policy_number,
                  user: UserData.id,
                  data: {
                        customer: {
                              legal_entity: le,
                              legal_entity_name: values.legal_entity_name.toUpperCase(),
                              first_name: values.first_name,
                              last_name: values.last_name,
                              address_1: values.address_1,
                              address_2: values.address_2,
                              address_3: values.address_3,
                              town: values.town,
                              county: values.county,
                              postcode: values.postcode.toUpperCase()
                        },
                        risk: {
                              vehicles: parseInt(values.vehicles),
                              git: parseInt(values.git),
                              pl: values.pl,
                              el: values.el,
                              europe: values.europe
                        },
                        rating: {
                              effective_date: effectiveDate,
                              premium: round(parseFloat(values.premium), 2),
                              fee: round(parseFloat(values.fee), 2)
                        },
                        payment: {
                              type: values.payment_type.toLowerCase(),
                              ref: paymentRef
                        }
                  }
            }

            console.log(JSON.stringify(data))

            let result = await axios.post(`${AppData.api.base}/policy/update`, data)
                  .catch(function (error) {
                        console.log(error)
                        message.error('Something went wrong!')
                        setLoading(false)
                        setBtnText('Process Mid-Term Adjustment')

                  })

            console.log(JSON.stringify(result.data))

            setLoading(false)

            if (result.data.status === "success") {
                  message.success(`${policy_number} modified successfully`)
                  // setIsAdjusted(true)
                  updatePolicy()
            } else {
                  setBtnText('Process Mid-Term Adjustment')
                  message.error(result.data.result)
            }

      }

      const onFinishFailed = (errorInfo) => {
            message.error('The form contains an error. Please review and fix it!')
      }

      const onChange = (date, dateString) => {
            setEffectiveDate(dateString)
      }

      const disabledDate = (current) => {
            // Can not select days before today and today
            return current && current < dayjs(startDate)
      }


      const onPaymentChange = (e) => {
            // console.log(e.target.value)
            e.target.value === "OPAYO" ? setPaymentRef('') : setPaymentRef(data.cbpf_ref)
            e.target.value === "OPAYO" ? setPaymentRefPlaceholder('eg Tboxguard-230927112759-128') : setPaymentRefPlaceholder('')
            setPaymentType(e.target.value)
      }

      const onPaymentRefChange = (e) => {
            // console.log(e.target.value)
            setPaymentRef(e.target.value)
      }

      return (
            <div >
                  <Form
                        style={{ minWidth: "400px" }}
                        name="basic"
                        layout="vertical"
                        initialValues={{
                              remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                  >

                        <h2 style={{ marginBottom: '25px' }}>Business information</h2>

                        <Form.Item
                              label="Legal Entity"
                              name="legal_entity"
                              initialValue={entity}
                        >
                              <Select
                                    defaultValue={entity}
                                    options={[
                                          { value: "Limited Company", label: "Limited Company" },
                                          { value: "Sole Trader", label: "Sole Trader" },
                                          { value: 'Limited Liability Partnership', label: 'Limited Liability Partnership' },
                                          { value: 'Partnership', label: 'Partnership' }
                                    ]}
                              />
                        </Form.Item>

                        <Form.Item
                              label={
                                    <div>
                                          Business Name{" "}
                                          <Tooltip title="Sole trader should always be 'FIRSTNAME LASTNAME'">
                                                <InfoCircleOutlined style={{ marginLeft: "5px", color: "rgba(98, 195, 208, 1)" }} />
                                          </Tooltip>
                                    </div>
                              }
                              name="legal_entity_name"
                              initialValue={data.legal_entity_name}
                              onInput={e => e.target.value = e.target.value.toUpperCase()}
                              rules={[
                                    { required: true, message: 'Please enter the correct legal entity!' }
                              ]}
                        >
                              <Input />
                        </Form.Item>

                        <Divider style={{ marginTop: '75px' }} />
                        <h2 style={{ marginBottom: '25px' }}>Contact information</h2>

                        <Form.Item
                              label="First name(s)"
                              name="first_name"
                              initialValue={data.first_name}
                              rules={[
                                    { required: true, message: 'Please enter the first name!' }
                              ]}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              label="Last name"
                              name="last_name"
                              initialValue={data.last_name}
                              rules={[
                                    { required: true, message: 'Please enter the last name!' }
                              ]}
                        >
                              <Input />
                        </Form.Item>

                        <Divider style={{ marginTop: '75px' }} />
                        <h2 style={{ marginBottom: '25px' }}>Address information</h2>

                        <Form.Item
                              label="Address #1"
                              name="address_1"
                              initialValue={data.address_1}
                              rules={[
                                    { required: true, message: 'Please input the first line of the address!' }
                              ]}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              // label="Address #2"
                              name="address_2"
                              initialValue={data.address_2}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              // label="Address #3"
                              name="address_3"
                              initialValue={data.address_3}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              label="Town"
                              name="town"
                              initialValue={data.town}
                              rules={[
                                    { required: true, message: 'Please enter the town!' }
                              ]}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              label="County"
                              name="county"
                              initialValue={data.county}
                              rules={[
                                    { required: true, message: 'Please enter the county!' }
                              ]}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              label="Postcode"
                              name="postcode"
                              initialValue={data.postcode}
                              onInput={e => e.target.value = e.target.value.toUpperCase()}
                              rules={[
                                    {
                                          required: true,
                                          pattern: new RegExp(/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/),
                                          message: 'Please enter a valid UK postcode',
                                    }
                              ]}
                        >
                              <Input />
                        </Form.Item>

                        <Divider style={{ marginTop: '75px' }} />
                        <h2 style={{ marginBottom: '25px' }}>Cover information</h2>

                        <Form.Item
                              label="Vehicles"
                              name="vehicles"
                              initialValue={data.vehicles}
                        >
                              <Select
                                    defaultValue={data.vehicles}
                                    options={[
                                          { value: '1', label: '1' },
                                          { value: '2', label: '2' },
                                          { value: '3', label: '3' },
                                          { value: '4', label: '4' },
                                          { value: '5', label: '5' }
                                    ]}
                              />
                        </Form.Item>

                        <Form.Item
                              label="Goods in transit"
                              name="git"
                              initialValue={data.git.toFixed(0)}
                        >
                              <Select
                                    defaultValue={data.git.toFixed(0)}
                                    options={[
                                          { value: '10', label: '£10,000' },
                                          { value: '15', label: '£15,000' },
                                          { value: '20', label: '£20,000' },
                                          { value: '25', label: '£25,000' },
                                          { value: '30', label: '£30,000' },
                                          { value: '35', label: '£35,000' },
                                          { value: '40', label: '£40,000' },
                                          { value: '50', label: '£50,000' },
                                    ]}
                              />
                        </Form.Item>

                        <Form.Item
                              label="Public liability"
                              name="pl"
                              initialValue={data.pl}
                        >
                              <Select
                                    defaultValue={data.pl}
                                    options={[
                                          { value: '0', label: 'Not required' },
                                          { value: '1', label: '£1,000,000' },
                                          { value: '2', label: '£2,000,000' },
                                          { value: '5', label: '£5,000,000' }
                                    ]}
                              />
                        </Form.Item>

                        <Form.Item
                              label="Employers liability"
                              name="el"
                              initialValue={data.el}
                        >
                              <Select
                                    defaultValue={data.el}
                                    options={[
                                          { value: '10', label: 'Yes' },
                                          { value: '0', label: 'Not required' }
                                    ]}
                              />
                        </Form.Item>

                        <Form.Item
                              label="Europe"
                              name="europe"
                              initialValue={data.europe}
                        >
                              <Select
                                    value={data.europe}
                                    defaultValue={data.europe}
                                    options={[
                                          { value: '1', label: 'Yes' },
                                          { value: '0', label: 'Not required' }
                                    ]}
                              />
                        </Form.Item>

                        <Divider style={{ marginTop: '75px' }} />
                        <h2 style={{ marginBottom: '25px' }}>Payment & fees</h2>

                        <Form.Item
                              label="Effective date"
                              name="date"
                              rules={[
                                    { required: true, message: 'Please select the effective date!' }
                              ]}
                        >
                              <DatePicker style={{ minWidth: "400px" }} onChange={onChange} disabledDate={disabledDate} />
                        </Form.Item>

                        <Form.Item
                              label="Additional Premium - Inclusive of IPT"
                              name="premium"
                              rules={[
                                    { required: true, message: 'Please input the premium!' }
                              ]}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              label="Our MTA Fee"
                              name="fee"
                              rules={[
                                    { required: true, message: 'Please input a fee!' }
                              ]}
                        >
                              <Input />
                        </Form.Item>



                        <Form.Item
                              label="Payment Type"
                              name="payment_type"
                              initialValue={data.payment_type.toLowerCase() !== "f" ? "CBPF" : "OPAYO"}
                              rules={[
                                    { required: true, message: 'Please select payment type!' }
                              ]}
                        >
                              <Radio.Group onChange={onPaymentChange} value={paymentType}>
                                    {data.payment_type.toLowerCase() !== "f" ? <Radio value="CBPF">CBPF</Radio> : null}
                                    <Radio value="OPAYO">OPAYO</Radio>
                              </Radio.Group>
                        </Form.Item>

                        <Form.Item
                              style={paymentType === "CBPF" ? { visibility: "hidden" } : {}}
                              label={paymentType === "OPAYO" ? `My Opayo Payment Ref` : `CBPF Loan Ref`}
                              rules={[
                                    { required: true, message: 'Please input the payment reference!' }
                              ]}
                        >
                              <Input allowClear defaultValue={paymentRef} value={paymentRef} onChange={onPaymentRefChange} placeholder={paymentRefPlaceholder} />
                        </Form.Item>

                        <Form.Item>
                              <Button type="primary" htmlType="submit" style={{ minWidth: "400px", backgroundColor: "#008ED7" }} loading={loading} disabled={loading}>
                                    {btnText}
                              </Button>
                        </Form.Item>
                  </Form>
            </div>
      )

}


