import { DatePicker } from 'antd'
import { useContext, useState, useEffect } from "react"
import { MyContext } from "../../App"
import dayjs from 'dayjs'
const { RangePicker } = DatePicker

const userDateFormat = 'DD-MMM-YYYY'
const systemDateFormat = 'YYYY-MM-DD'

export function Datepicker({ config }) {

      const width = window.innerWidth

      const { UserData, setUserData } = useContext(MyContext)
      const [ rangeValues, setRangeValues ] = useState([ dayjs().subtract(config.datepicker.period, config.datepicker.period_type), dayjs() ])

      const onChange = (date) => {
            const START = dayjs(date[ 0 ]).format(systemDateFormat)
            const END = dayjs(date[ 1 ]).format(systemDateFormat)
            console.log('datepicker changed', [ START, END ])
            setUserData({ report_date_range: [ START, END ] })
      }

      useEffect(() => {

            if (UserData?.report_date_range) { setRangeValues([ dayjs(UserData.report_date_range[ 0 ]), dayjs(UserData.report_date_range[ 1 ]) ]) }

      }, [ UserData ])

      const rangePresets = [
            {
                  label: 'Last 7 Days',
                  value: [ dayjs().add(-7, 'd'), dayjs() ],
            },
            {
                  label: 'Last 14 Days',
                  value: [ dayjs().add(-14, 'd'), dayjs() ],
            },
            {
                  label: 'Last 30 Days',
                  value: [ dayjs().add(-30, 'd'), dayjs() ],
            },
            {
                  label: 'Last 90 Days',
                  value: [ dayjs().add(-90, 'd'), dayjs() ],
            },
            {
                  label: 'Last 180 Days',
                  value: [ dayjs().add(-180, 'd'), dayjs() ],
            },
            {
                  label: 'Last 365 Days',
                  value: [ dayjs().add(-365, 'd'), dayjs() ],
            }
      ]

      return (

            <RangePicker
                  size="large"
                  presets={rangePresets}
                  onChange={onChange}
                  format={userDateFormat}
                  style={width < 992 ? { width: "100%", float: "left" } : { float: "right", width: "300px", verticalAlign: "top", marginRight: "5px" }}
                  value={rangeValues}
                  defaultPickerValue={rangeValues}
                  defaultValue={rangeValues}
            />

      )

} 