import { Row, Col, Skeleton, Segmented } from "antd"
import { Header } from "../../Header"
import { Table } from "../../tables/Table"
import { useEffect, useState, useContext } from "react"
import dayjs from "dayjs"
import { MyContext } from "../../../App"
import { defaultTableConfig } from "../../../libs/config"
import { get, round, numberWithCommas } from "../../../libs/helper"
import { MdOutlineFilterTiltShift } from "react-icons/md"

export function PerformanceAnalysis() {

      const { AppData } = useContext(MyContext)

      const [ loading, setLoading ] = useState(true)
      const [ fqTableData, setFqTableData ] = useState([])
      const [ bgTableData, setBgTableData ] = useState([])
      const [ combinedTableData, setCombinedTableData ] = useState([])

      const [ filters, setFilters ] = useState({ driver: "All Drivers", payment: "All Payments" })

      useEffect(() => {
            console.log(filters)
      }, [ filters ])



      const getFQ = async (query) => {

            const params = {
                  brand: "fastquote",
                  table: "policies",
                  query
            }

            const { result } = await get(params, AppData)

            return result

      }

      const getBG = async (query) => {

            const params = {
                  brand: "boxguard",
                  table: "policies",
                  query
            }

            const { result } = await get(params, AppData)

            return result

      }

      // Gets data from db
      const getData = async () => {

            // Handle filters
            let customWhere = `payment_completed >= '${dayjs().subtract(365, 'd').format("YYYY-MM-DD HH:mm:ss")}'`

            // Driver filters
            if (filters.driver.toLowerCase() === "couriers") { customWhere += ` AND LOWER(driver_type) = 'c'` }
            else if (filters.driver.toLowerCase() === "removers") { customWhere += ` AND LOWER(driver_type) = 'r'` }

            // Payment filters
            if (filters.payment.toLowerCase() === "paid in full") { customWhere += ` AND LOWER(payment_type) = 'f'` }
            else if (filters.payment.toLowerCase() === "premium finance") { customWhere += ` AND LOWER(payment_type) <> 'f'` }

            console.log(customWhere)

            // Build query
            const query = {
                  select: "payment_completed, driver_type, payment_type, net_premium, commission, fee",
                  where: { [ customWhere ]: "" },
                  order: { payment_completed: "ASC" }
            }

            const [ fqData, bgData ] = await Promise.all([ getFQ(query), getBG(query) ])
            const [ fqTable, bgTable ] = await Promise.all([ buildTableData('fq', fqData), buildTableData('bg', bgData) ])
            setFqTableData(fqTable)
            setBgTableData(bgTable)

            // merge for combined
            const combinedArray = mergeAndSumArrayValues(fqTable, bgTable)
            // console.table(combinedArray)
            setCombinedTableData(combinedArray)
      }

      useEffect(() => {

            (async () => {

                  setLoading(true)
                  await getData()
                  setLoading(false)

            })()

            // eslint-disable-next-line
      }, [ filters ])

      const columns = [
            {
                  name: '',
                  dataIndex: 'name',
                  key: 'name'
            },
            {
                  name: `${dayjs().subtract(365, 'days').format("MMM YY")}`,
                  key: 'val1',
                  render: (item, record) => {
                        return (record.val1)
                  }
            },
            {
                  name: dayjs().subtract(11, 'month').format("MMM YY"),
                  key: 'val2',
                  render: (item, record) => {
                        return (record.val2)
                  }
            },
            {
                  name: dayjs().subtract(10, 'month').format("MMM YY"),
                  key: 'val3',
                  render: (item, record) => {
                        return (record.val3)
                  }
            },
            {
                  name: dayjs().subtract(9, 'month').format("MMM YY"),
                  key: 'val4',
                  render: (item, record) => {
                        return (record.val4)
                  }
            },
            {
                  name: dayjs().subtract(8, 'month').format("MMM YY"),
                  key: 'val5',
                  render: (item, record) => {
                        return (record.val5)
                  }
            },
            {
                  name: dayjs().subtract(7, 'month').format("MMM YY"),
                  key: 'val6',
                  render: (item, record) => {
                        return (record.val6)
                  }
            },
            {
                  name: dayjs().subtract(6, 'month').format("MMM YY"),
                  key: 'val7',
                  render: (item, record) => {
                        return (record.val7)
                  }
            },
            {
                  name: dayjs().subtract(5, 'month').format("MMM YY"),
                  key: 'val8',
                  render: (item, record) => {
                        return (record.val8)
                  }
            },
            {
                  name: dayjs().subtract(4, 'month').format("MMM YY"),
                  key: 'val9',
                  render: (item, record) => {
                        return (record.val9)
                  }
            },
            {
                  name: dayjs().subtract(3, 'month').format("MMM YY"),
                  key: 'val10',
                  render: (item, record) => {
                        return (record.val10)
                  }
            },
            {
                  name: dayjs().subtract(2, 'month').format("MMM YY"),
                  key: 'val11',
                  render: (item, record) => {
                        return (record.val11)
                  }
            },
            {
                  name: dayjs().subtract(1, 'month').format("MMM YY"),
                  key: 'val12',
                  render: (item, record) => {
                        return (record.val12)
                  }
            },
            {
                  name: dayjs().subtract(0, 'month').format("MMM YY"),
                  key: 'val13',
                  render: (item, record) => {
                        return (record.val13)
                  }
            },
            {
                  name: "TTM",
                  key: 'val14',
                  render: (item, record) => {
                        return (record.val14)
                  }
            },
      ]

      const customTableConfig = {
            columns,
            search: {
                  active: false
            },
            export: {
                  active: false
            },
            datepicker: {
                  active: false
            },
            pagination: {
                  active: false
            }
      }

      const fqContent =
            <><h2>Fastquote</h2>
                  <Table
                        rowKey="index"
                        data={fqTableData}
                        config={{ ...defaultTableConfig, ...customTableConfig }}
                        size="small"
                  />
            </>

      const bgContent =
            <><h2>Boxguard</h2>
                  <Table
                        rowKey="index"
                        data={bgTableData}
                        config={{ ...defaultTableConfig, ...customTableConfig }}
                        size="small"
                  />
            </>

      const combinedContent =
            <><h2>Total Dasher</h2>
                  <Table
                        rowKey="index"
                        data={combinedTableData}
                        config={{ ...defaultTableConfig, ...customTableConfig }}
                        size="small"
                  />
            </>

      const filterChange = (type, event) => {
            console.log(type)
            console.log(event)
      }

      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={21}>

                                    <Row className="pagetitle">

                                          <Col>
                                                <h1>Performance Analysis - TTM</h1>
                                                <p>Designed to keep track on trailing progress</p>
                                          </Col>

                                    </Row>

                                    <Row>
                                          <Col span={24} style={{ marginBottom: 10 }}>
                                                <Segmented style={{ float: "right" }} onChange={(e) => setFilters({ ...filters, ...{ driver: e } })} defaultValue={filters.driver} options={[ 'Couriers', 'Removers', 'All Drivers' ]} />
                                          </Col>
                                    </Row>
                                    <Row>
                                          <Col span={24} style={{ marginBottom: 10 }}>
                                                <Segmented style={{ float: "right" }} onChange={(e) => setFilters({ ...filters, ...{ payment: e } })} defaultValue={filters.payment} options={[ 'Paid in full', 'Premium Finance', 'All Payments' ]} />
                                          </Col>
                                    </Row>

                                    <div className="card" >

                                          {loading
                                                ? <Skeleton active />
                                                : fqContent
                                          }

                                    </div>

                                    <div className="card" >

                                          {loading
                                                ? <Skeleton active />
                                                : bgContent
                                          }

                                    </div>

                                    <div className="card" >

                                          {loading
                                                ? <Skeleton active />
                                                : combinedContent
                                          }

                                    </div>

                              </Col>

                        </Row>

                  </div>

            </>

      )

}

const buildTableData = async (brand, data) => {

      let i = 12
      let build_arr = []
      while (i >= 0) {

            let period_name = `${dayjs().subtract(i, 'month').format("MMM")}-${dayjs().subtract(i, 'month').format("YY")}`

            const period_start = i === 12
                  ? dayjs().subtract(365, 'day')
                  : dayjs().subtract(i, 'month').startOf('month')

            const period_end = i === 0
                  ? dayjs()
                  : dayjs().subtract(i, 'month').endOf('month')

            const timeFilteredData = data.filter(item => {
                  const paymentDate = dayjs(item.payment_completed)
                  return paymentDate.isAfter(period_start) && paymentDate.isBefore(period_end)
            })

            const net_premium = timeFilteredData.reduce((sum, item) => round(sum + parseFloat(item.net_premium), 2), 0)
            const commission = timeFilteredData.reduce((sum, item) => round(sum + parseFloat(item.commission), 2), 0)
            const commission_percent = round(commission / net_premium, 2)
            const fee = timeFilteredData.reduce((sum, item) => round(sum + parseFloat(item.fee), 2), 0)
            const revenue = round(commission + fee, 2)
            const policies = timeFilteredData.length
            const average_revenue = round(revenue / policies, 2)
            const average_net_premium = round(net_premium / policies, 2)
            const average_fee = round(fee / policies, 2)

            const obj = {
                  period: period_name,
                  period_start: dayjs(period_start).format("YYYY-MM-DD HH:mm:ss"),
                  period_end: dayjs(period_end).format("YYYY-MM-DD HH:mm:ss"),
                  policies,
                  net_premium,
                  average_net_premium,
                  commission,
                  commission_percent,
                  fee,
                  average_fee,
                  revenue,
                  average_revenue
            }

            build_arr[ i ] = obj
            i--
      }

      build_arr = build_arr.reverse()

      // Push TTM on the end

      const ttmFilteredData = data.filter(item => {
            const paymentDate = dayjs(item.payment_completed)
            return paymentDate.isAfter(dayjs().subtract(365, 'day'))
      })

      const net_premium = ttmFilteredData.reduce((sum, item) => round(sum + parseFloat(item.net_premium), 2), 0)
      const commission = ttmFilteredData.reduce((sum, item) => round(sum + parseFloat(item.commission), 2), 0)
      const commission_percent = round(commission / net_premium, 2)
      const fee = ttmFilteredData.reduce((sum, item) => round(sum + parseFloat(item.fee), 2), 0)
      const revenue = round(commission + fee, 2)
      const policies = ttmFilteredData.length
      const average_revenue = round(revenue / policies, 2)
      const average_net_premium = round(net_premium / policies, 2)
      const average_fee = round(fee / policies, 2)

      const ttmObj = {
            period: "TTM",
            period_start: dayjs().subtract(365, 'day').format("YYYY-MM-DD HH:mm:ss"),
            period_end: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            policies,
            net_premium,
            average_net_premium,
            commission,
            commission_percent,
            fee,
            average_fee,
            revenue,
            average_revenue
      }

      build_arr.push(ttmObj)

      // console.log('buildTableData', brand, build_arr)

      const tableArr = [
            {
                  name: "Policies",
                  val1: numberWithCommas(round(build_arr[ 0 ].policies, 0)),
                  val2: numberWithCommas(round(build_arr[ 1 ].policies, 0)),
                  val3: numberWithCommas(round(build_arr[ 2 ].policies, 0)),
                  val4: numberWithCommas(round(build_arr[ 3 ].policies, 0)),
                  val5: numberWithCommas(round(build_arr[ 4 ].policies, 0)),
                  val6: numberWithCommas(round(build_arr[ 5 ].policies, 0)),
                  val7: numberWithCommas(round(build_arr[ 6 ].policies, 0)),
                  val8: numberWithCommas(round(build_arr[ 7 ].policies, 0)),
                  val9: numberWithCommas(round(build_arr[ 8 ].policies, 0)),
                  val10: numberWithCommas(round(build_arr[ 9 ].policies, 0)),
                  val11: numberWithCommas(round(build_arr[ 10 ].policies, 0)),
                  val12: numberWithCommas(round(build_arr[ 11 ].policies, 0)),
                  val13: numberWithCommas(round(build_arr[ 12 ].policies, 0)),
                  val14: numberWithCommas(round(build_arr[ 13 ].policies, 0)),
            },
            {
                  name: "Net Premium (x-IPT)",
                  val1: numberWithCommas(round(build_arr[ 0 ].net_premium, 0)),
                  val2: numberWithCommas(round(build_arr[ 1 ].net_premium, 0)),
                  val3: numberWithCommas(round(build_arr[ 2 ].net_premium, 0)),
                  val4: numberWithCommas(round(build_arr[ 3 ].net_premium, 0)),
                  val5: numberWithCommas(round(build_arr[ 4 ].net_premium, 0)),
                  val6: numberWithCommas(round(build_arr[ 5 ].net_premium, 0)),
                  val7: numberWithCommas(round(build_arr[ 6 ].net_premium, 0)),
                  val8: numberWithCommas(round(build_arr[ 7 ].net_premium, 0)),
                  val9: numberWithCommas(round(build_arr[ 8 ].net_premium, 0)),
                  val10: numberWithCommas(round(build_arr[ 9 ].net_premium, 0)),
                  val11: numberWithCommas(round(build_arr[ 10 ].net_premium, 0)),
                  val12: numberWithCommas(round(build_arr[ 11 ].net_premium, 0)),
                  val13: numberWithCommas(round(build_arr[ 12 ].net_premium, 0)),
                  val14: numberWithCommas(round(build_arr[ 13 ].net_premium, 0)),
            },
            {
                  name: "Avg Net Premium",
                  val1: numberWithCommas(round(build_arr[ 0 ].average_net_premium, 0)),
                  val2: numberWithCommas(round(build_arr[ 1 ].average_net_premium, 0)),
                  val3: numberWithCommas(round(build_arr[ 2 ].average_net_premium, 0)),
                  val4: numberWithCommas(round(build_arr[ 3 ].average_net_premium, 0)),
                  val5: numberWithCommas(round(build_arr[ 4 ].average_net_premium, 0)),
                  val6: numberWithCommas(round(build_arr[ 5 ].average_net_premium, 0)),
                  val7: numberWithCommas(round(build_arr[ 6 ].average_net_premium, 0)),
                  val8: numberWithCommas(round(build_arr[ 7 ].average_net_premium, 0)),
                  val9: numberWithCommas(round(build_arr[ 8 ].average_net_premium, 0)),
                  val10: numberWithCommas(round(build_arr[ 9 ].average_net_premium, 0)),
                  val11: numberWithCommas(round(build_arr[ 10 ].average_net_premium, 0)),
                  val12: numberWithCommas(round(build_arr[ 11 ].average_net_premium, 0)),
                  val13: numberWithCommas(round(build_arr[ 12 ].average_net_premium, 0)),
                  val14: numberWithCommas(round(build_arr[ 13 ].average_net_premium, 0)),
            },
            {
                  name: "Commission",
                  val1: numberWithCommas(round(build_arr[ 0 ].commission, 0)),
                  val2: numberWithCommas(round(build_arr[ 1 ].commission, 0)),
                  val3: numberWithCommas(round(build_arr[ 2 ].commission, 0)),
                  val4: numberWithCommas(round(build_arr[ 3 ].commission, 0)),
                  val5: numberWithCommas(round(build_arr[ 4 ].commission, 0)),
                  val6: numberWithCommas(round(build_arr[ 5 ].commission, 0)),
                  val7: numberWithCommas(round(build_arr[ 6 ].commission, 0)),
                  val8: numberWithCommas(round(build_arr[ 7 ].commission, 0)),
                  val9: numberWithCommas(round(build_arr[ 8 ].commission, 0)),
                  val10: numberWithCommas(round(build_arr[ 9 ].commission, 0)),
                  val11: numberWithCommas(round(build_arr[ 10 ].commission, 0)),
                  val12: numberWithCommas(round(build_arr[ 11 ].commission, 0)),
                  val13: numberWithCommas(round(build_arr[ 12 ].commission, 0)),
                  val14: numberWithCommas(round(build_arr[ 13 ].commission, 0)),
            },
            {
                  name: "Commission %",
                  val1: `${round(build_arr[ 0 ].commission_percent, 2)}%`,
                  val2: `${round(build_arr[ 1 ].commission_percent, 2)}%`,
                  val3: `${round(build_arr[ 2 ].commission_percent, 2)}%`,
                  val4: `${round(build_arr[ 3 ].commission_percent, 2)}%`,
                  val5: `${round(build_arr[ 4 ].commission_percent, 2)}%`,
                  val6: `${round(build_arr[ 5 ].commission_percent, 2)}%`,
                  val7: `${round(build_arr[ 6 ].commission_percent, 2)}%`,
                  val8: `${round(build_arr[ 7 ].commission_percent, 2)}%`,
                  val9: `${round(build_arr[ 8 ].commission_percent, 2)}%`,
                  val10: `${round(build_arr[ 9 ].commission_percent, 2)}%`,
                  val11: `${round(build_arr[ 10 ].commission_percent, 2)}%`,
                  val12: `${round(build_arr[ 11 ].commission_percent, 2)}%`,
                  val13: `${round(build_arr[ 12 ].commission_percent, 2)}%`,
                  val14: `${round(build_arr[ 13 ].commission_percent, 2)}%`,
            },
            {
                  name: "Fee",
                  val1: numberWithCommas(round(build_arr[ 0 ].fee, 0)),
                  val2: numberWithCommas(round(build_arr[ 1 ].fee, 0)),
                  val3: numberWithCommas(round(build_arr[ 2 ].fee, 0)),
                  val4: numberWithCommas(round(build_arr[ 3 ].fee, 0)),
                  val5: numberWithCommas(round(build_arr[ 4 ].fee, 0)),
                  val6: numberWithCommas(round(build_arr[ 5 ].fee, 0)),
                  val7: numberWithCommas(round(build_arr[ 6 ].fee, 0)),
                  val8: numberWithCommas(round(build_arr[ 7 ].fee, 0)),
                  val9: numberWithCommas(round(build_arr[ 8 ].fee, 0)),
                  val10: numberWithCommas(round(build_arr[ 9 ].fee, 0)),
                  val11: numberWithCommas(round(build_arr[ 10 ].fee, 0)),
                  val12: numberWithCommas(round(build_arr[ 11 ].fee, 0)),
                  val13: numberWithCommas(round(build_arr[ 12 ].fee, 0)),
                  val14: numberWithCommas(round(build_arr[ 13 ].fee, 0)),
            },
            {
                  name: "Avg Fee",
                  val1: numberWithCommas(round(build_arr[ 0 ].average_fee, 0)),
                  val2: numberWithCommas(round(build_arr[ 1 ].average_fee, 0)),
                  val3: numberWithCommas(round(build_arr[ 2 ].average_fee, 0)),
                  val4: numberWithCommas(round(build_arr[ 3 ].average_fee, 0)),
                  val5: numberWithCommas(round(build_arr[ 4 ].average_fee, 0)),
                  val6: numberWithCommas(round(build_arr[ 5 ].average_fee, 0)),
                  val7: numberWithCommas(round(build_arr[ 6 ].average_fee, 0)),
                  val8: numberWithCommas(round(build_arr[ 7 ].average_fee, 0)),
                  val9: numberWithCommas(round(build_arr[ 8 ].average_fee, 0)),
                  val10: numberWithCommas(round(build_arr[ 9 ].average_fee, 0)),
                  val11: numberWithCommas(round(build_arr[ 10 ].average_fee, 0)),
                  val12: numberWithCommas(round(build_arr[ 11 ].average_fee, 0)),
                  val13: numberWithCommas(round(build_arr[ 12 ].average_fee, 0)),
                  val14: numberWithCommas(round(build_arr[ 13 ].average_fee, 0)),
            },
            {
                  name: "Revenue (C+F)",
                  val1: numberWithCommas(round(build_arr[ 0 ].revenue, 0)),
                  val2: numberWithCommas(round(build_arr[ 1 ].revenue, 0)),
                  val3: numberWithCommas(round(build_arr[ 2 ].revenue, 0)),
                  val4: numberWithCommas(round(build_arr[ 3 ].revenue, 0)),
                  val5: numberWithCommas(round(build_arr[ 4 ].revenue, 0)),
                  val6: numberWithCommas(round(build_arr[ 5 ].revenue, 0)),
                  val7: numberWithCommas(round(build_arr[ 6 ].revenue, 0)),
                  val8: numberWithCommas(round(build_arr[ 7 ].revenue, 0)),
                  val9: numberWithCommas(round(build_arr[ 8 ].revenue, 0)),
                  val10: numberWithCommas(round(build_arr[ 9 ].revenue, 0)),
                  val11: numberWithCommas(round(build_arr[ 10 ].revenue, 0)),
                  val12: numberWithCommas(round(build_arr[ 11 ].revenue, 0)),
                  val13: numberWithCommas(round(build_arr[ 12 ].revenue, 0)),
                  val14: numberWithCommas(round(build_arr[ 13 ].revenue, 0)),
            },
            {
                  name: "Avg Revenue",
                  val1: numberWithCommas(round(build_arr[ 0 ].average_revenue, 0)),
                  val2: numberWithCommas(round(build_arr[ 1 ].average_revenue, 0)),
                  val3: numberWithCommas(round(build_arr[ 2 ].average_revenue, 0)),
                  val4: numberWithCommas(round(build_arr[ 3 ].average_revenue, 0)),
                  val5: numberWithCommas(round(build_arr[ 4 ].average_revenue, 0)),
                  val6: numberWithCommas(round(build_arr[ 5 ].average_revenue, 0)),
                  val7: numberWithCommas(round(build_arr[ 6 ].average_revenue, 0)),
                  val8: numberWithCommas(round(build_arr[ 7 ].average_revenue, 0)),
                  val9: numberWithCommas(round(build_arr[ 8 ].average_revenue, 0)),
                  val10: numberWithCommas(round(build_arr[ 9 ].average_revenue, 0)),
                  val11: numberWithCommas(round(build_arr[ 10 ].average_revenue, 0)),
                  val12: numberWithCommas(round(build_arr[ 11 ].average_revenue, 0)),
                  val13: numberWithCommas(round(build_arr[ 12 ].average_revenue, 0)),
                  val14: numberWithCommas(round(build_arr[ 13 ].average_revenue, 0)),
            },
      ]

      // console.table(tableArr)

      return tableArr

}


function mergeAndSumArrayValues(arr1, arr2) {
      // Assuming both arrays have the same structure and length
      return arr1.map((obj, index) => {
            // Create a new object to store the sums
            const sumObj = {}

            for (const key in obj) {

                  if ((obj.name === "Avg Net Premium" || obj.name === "Commission %" || obj.name === "Avg Fee" || obj.name === "Avg Revenue") && key.startsWith('val')) {
                        sumObj[ key ] = '-'
                  }

                  else if (key.startsWith('val')) {
                        // Sum the values after converting them to numbers (they seem to be strings in your arrays)
                        sumObj[ key ] = numberWithCommas(round(parseFloat(obj[ key ].replace(/,/g, '')) + parseFloat(arr2[ index ][ key ].replace(/,/g, '')), 2))
                  }

                  else {
                        // Copy over non-numeric values directly
                        sumObj[ key ] = obj[ key ]
                  }

            }
            return sumObj
      })
}