import { SearchOutlined } from '@ant-design/icons'
import { useEffect, useState, useRef, useContext } from "react"
import { BsCircleFill } from "react-icons/bs"
import { MdOutlineDataSaverOn } from "react-icons/md"
import { Button, Input, Table, Tag, message } from 'antd'
import Highlighter from 'react-highlight-words'
import dayjs from "dayjs"
import { MyContext } from "../App"
import { get, update } from "../libs/helper"

export function IncompleteTable() {

      const { AppData, UserData } = useContext(MyContext)

      const [ data, setData ] = useState([])

      useEffect(() => {

            (async () => {

                  // Configure data request
                  const params = {
                        brand: "all",
                        table: "policies",
                        query: {
                              select: `id, cancelled, revenue, created, policy_number, legal_entity, legal_entity_name, driver_type, CONCAT(first_name," ",last_name) AS full_name, payment_type, email, telephone, git, pl, el`,
                              where: {
                                    [ `created > DATE_SUB(now(), INTERVAL 1 MONTH)` ]: "",
                                    [ `created < now() - INTERVAL 15 MINUTE` ]: "",
                                    [ "docs_issued IS NULL" ]: "",
                                    [ "payment_type <> ''" ]: "",
                                    [ "legal_entity_name <> ''" ]: ""
                              },
                              order: { created: "DESC" }
                        }
                  }

                  // Request data
                  const { result } = await get(params, AppData)
                  setData(result)

            })()

      }, [ AppData ])

      const [ searchText, setSearchText ] = useState('')
      const [ searchedColumn, setSearchedColumn ] = useState('')
      const searchInput = useRef(null)

      const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm()
            setSearchText(selectedKeys[ 0 ])
            setSearchedColumn(dataIndex)
      }
      const handleReset = (clearFilters) => {
            clearFilters()
            setSearchText('')
      }
      const getColumnSearchProps = (dataIndex) => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                  <div
                        style={{
                              padding: 8,
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                  >
                        <Input
                              ref={searchInput}
                              placeholder={`Search ${dataIndex}`}
                              value={selectedKeys[ 0 ]}
                              onChange={(e) => setSelectedKeys(e.target.value ? [ e.target.value ] : [])}
                              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                              style={{
                                    marginBottom: 8,
                                    display: 'block',
                              }}
                        />

                        <Button
                              type="primary"
                              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                              icon={<SearchOutlined />}
                              size="small"
                              style={{
                                    width: 90,
                              }}
                        >
                              Search
                        </Button>
                        <Button
                              onClick={() => clearFilters && handleReset(clearFilters)}
                              size="small"
                              style={{
                                    width: 90,
                              }}
                        >
                              Reset
                        </Button>
                        <Button
                              type="link"
                              size="small"
                              onClick={() => {
                                    confirm({
                                          closeDropdown: false,
                                    })
                                    setSearchText(selectedKeys[ 0 ])
                                    setSearchedColumn(dataIndex)
                              }}
                        >
                              Filter
                        </Button>
                        <Button
                              type="link"
                              size="small"
                              onClick={() => {
                                    close()
                              }}
                        >
                              close
                        </Button>

                  </div>
            ),
            filterIcon: (filtered) => (
                  <SearchOutlined
                        style={{
                              color: filtered ? '#1890ff' : undefined,
                        }}
                  />
            ),
            onFilter: (value, record) =>
                  record[ dataIndex ].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownOpenChange: (visible) => {
                  if (visible) {
                        setTimeout(() => searchInput.current?.select(), 100)
                  }
            },
            render: (text) =>
                  searchedColumn === dataIndex ? (
                        <Highlighter
                              highlightStyle={{
                                    backgroundColor: '#ffc069',
                                    padding: 0,
                              }}
                              searchWords={[ searchText ]}
                              autoEscape
                              textToHighlight={text ? text.toString() : ''}
                        />
                  ) : (
                        text
                  ),
      })
      const columns = [
            {
                  title: '',
                  key: 'status',
                  render: (item) => {
                        return (<BsCircleFill style={{ fontSize: '12px', verticalAlign: '-2px', color: "#CF1321" }} />)
                  }
            },
            {
                  title: 'Time/Date',
                  dataIndex: 'created',
                  key: 'created',
                  render: (text, record) => (
                        <span>{dayjs(record.created).format("HH:mm DD MMM YYYY")} </span>
                  )
            },
            {
                  title: 'Information',
                  key: "id",
                  render: (item) => {
                        const brand = item.policy_number.includes("BG") ? "BOXGUARD" : "FASTQUOTE"
                        const color = item.policy_number.includes("BG") ? "green" : "blue"
                        const etype = item.legal_entity.includes("LC") || item.legal_entity.includes("Limited Company") ? "LIMITED COMPANY" : "SOLE TRADER"
                        const dtype = item.driver_type.includes("C") || item.driver_type.includes("c") ? "COURIER" : "REMOVER"
                        const git = `GIT £${item.git}k`
                        const pl = `PL £${item.pl}m`
                        const el = `EL £${item.el}m`
                        const ptype = item.payment_type.toLowerCase() === "f" ? "PAID" : "CBPF"
                        return (<>
                              <Tag color={color}>{brand}</Tag>
                              <Tag>{etype}</Tag>
                              <Tag>{dtype}</Tag>
                              <Tag>{git}</Tag>
                              <Tag>{pl}</Tag>
                              <Tag>{el}</Tag>
                              <Tag>{ptype}</Tag>
                        </>)
                  }
            },
            {
                  title: 'Policy Number',
                  dataIndex: 'policy_number',
                  key: 'policy_number',
                  ...getColumnSearchProps('policy_number'),
            },
            {
                  title: 'Insured Entity',
                  dataIndex: 'legal_entity_name',
                  key: 'legal_entity_name',
                  ...getColumnSearchProps('legal_entity_name'),
            },
            {
                  title: 'Primary Contact',
                  dataIndex: 'full_name',
                  key: "full_name",
                  ...getColumnSearchProps('full_name'),
            },
            {
                  title: 'Email',
                  dataIndex: 'email',
                  key: "email"
            },
            {
                  title: 'Telephone',
                  dataIndex: 'telephone',
                  key: "telephone",
            },
            {
                  title: '',
                  render: (item) => {
                        if (UserData.accessLevel >= 2) {
                              return (<MdOutlineDataSaverOn className="clickable" onClick={() => payoff(item.policy_number)} style={{ fontSize: '20px', verticalAlign: '-5px', color: "green" }} />)
                        }
                  }
            }
      ]

      async function payoff(id) {

            const updateObj = {
                  brand: id.includes("BG") ? "boxguard" : "fastquote",
                  table: "policies",
                  data: {
                        where: { policy_number: id },
                        data: { payment_completed: dayjs().format("YYYY-MM-DD HH:mm:ss") }
                  }
            }

            let result = await update(updateObj, AppData)

            // Handle response
            result.status === "success"
                  ? message.success(`${id} completed. Please wait, records and docs will take 5-10 mins.`)
                  : message.error(`${id} failed. Contact your system administrator.`)

      }

      return (<><Table rowKey="id" style={{ marginTop: '75px' }} columns={columns} dataSource={data} pagination={{ defaultPageSize: 25, showSizeChanger: true, pageSizeOptions: [ '25', '50', '100', '500', '1000', '10000' ] }} /></>)

}

