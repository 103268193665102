import { useContext } from 'react'
import { Form, Input, Modal, Tag, Row, Col } from 'antd'
import { useState } from 'react'
import { MyContext } from '../App'
import { useNavigate } from "react-router-dom"
import { FaSearch as SearchOutlined } from "react-icons/fa"
import { get, debounce } from "../libs/helper"
import dayjs from "dayjs"


export function Search() {

      const navigate = useNavigate()
      const { AppData } = useContext(MyContext)

      const [ isModalOpen, setIsModalOpen ] = useState(false)

      const showModal = () => {
            setIsModalOpen(true)
      }
      const hideModal = () => {
            setIsModalOpen(false)
      }

      const [ data, setData ] = useState([])
      const [ guidance, setGuidance ] = useState("")

      const search = async (str) => {

            str = `REPLACE( '%${escape(str)}%', "%27", "'" )`

            // Configure data request
            const params = {
                  brand: "all",
                  table: "policies",
                  query: {
                        select: `policy_number, start_date, legal_entity_name, CONCAT(first_name," ",last_name) as full_name, email, telephone, crn, postcode`,
                        where: {
                              [ `(policy_number LIKE ${str} OR legal_entity_name LIKE ${str} OR first_name LIKE ${str} OR last_name LIKE ${str} OR email LIKE ${str} OR telephone LIKE ${str} OR crn LIKE ${str} OR postcode LIKE ${str}) AND docs_issued >= '${dayjs().subtract(6, 'y').format("YYYY-MM-DD HH:mm:ss")}'` ]: ""
                        },
                        order: { start_date: "DESC" }
                  }
            }

            // Request data
            const { result } = await get(params, AppData)
            setData(result)
            return result

      }

      const [ form ] = Form.useForm()

      const launchSearch = () => {
            form.resetFields()
            setData([])
            showModal()
      }

      const onInput = async (str) => {
            str = str.toLowerCase()
            if (str.length < 3) { setGuidance("Minimum 3 characters."); setData([]); return }
            if ((str.includes("bg-") || str.includes("fq-")) && str.length < 17) {
                  setGuidance("Keep typing the policy number. Search will start once 17 characters entered")
                  return
            }
            setGuidance("loading")
            await search(str)
            setGuidance("")

      }

      const debouncedOnInput = debounce(onInput, 500) // 500ms debounce time

      const searchResults = data.length > 0
            ? data.map((item, index) => (
                  <div
                        className="clickable"
                        style={{ padding: "10px", marginBottom: "5px", border: "1px solid #e8e8e8", backgroundColor: "rgba(55, 158, 12, 0.05)" }}
                        key={index}
                        onClick={() => { navigate(`/customer/view?id=${item.policy_number}`) }}
                  >
                        <Row>
                              <Col span={2}>{dayjs(item.start_date).format("DD-MMM-YY")}</Col>
                              <Col span={3}><Tag >{item.policy_number}</Tag></Col>
                              <Col span={6}>{item.legal_entity_name}</Col>
                              <Col span={5}>{item.full_name}</Col>
                              <Col flex="auto"><Tag >{item.postcode}</Tag><Tag >{item.telephone}</Tag><Tag >{item.email}</Tag></Col>
                        </Row>
                  </div>

            ))
            : <span style={{ opacity: 0.5, fontSize: "110%" }}>{guidance}</span>

      return (
            <>
                  <SearchOutlined className="clickable" onClick={launchSearch} style={{ color: '#E41882', fontSize: '28px', marginRight: "20px" }} />

                  <Modal
                        title=""
                        open={isModalOpen}
                        onCancel={hideModal}
                        width="80%"
                        closeIcon=" "
                        footer={null}
                  >
                        <div style={{ minHeight: window.innerHeight * 0.80 }}>
                              <Form>
                                    <Form.Item name="input">
                                          <Input
                                                style={{
                                                      width: "100%",
                                                      verticalAlign: '8px',
                                                      marginRight: "25px",
                                                      padding: "15px 25px",
                                                      fontSize: "24px"
                                                }}
                                                allowClear
                                                placeholder="Start typing ..."
                                                onChange={(e) => debouncedOnInput(e.target.value)}

                                          />
                                    </Form.Item>
                              </Form>

                              {searchResults}
                        </div>
                  </Modal>

            </>
      )
}