import { Row, Col, Skeleton, message } from "antd"
import { Header } from "../../Header"
import { Table } from "../../tables/Table"
import { useEffect, useState, useContext } from "react"
import dayjs from "dayjs"
import { MyContext } from "../../../App"
import { defaultTableConfig } from "../../../libs/config"
import { get } from "../../../libs/helper"

export function AccountingReconciliation() {

      const { AppData, UserData } = useContext(MyContext)
      const [ startDate, setStartDate ] = useState(dayjs().subtract(AppData.reports.default_period, AppData.reports.default_period_type).format("YYYY-MM-DD"))
      const [ endDate, setEndDate ] = useState(dayjs().format("YYYY-MM-DD"))
      const [ loading, setLoading ] = useState(true)
      const [ data, setData ] = useState([])

      useEffect(() => {

            if (UserData?.report_date_range) {
                  setStartDate(UserData.report_date_range[ 0 ])
                  setEndDate(UserData.report_date_range[ 1 ])
            }

      }, [ UserData.report_date_range ])

      useEffect(() => {

            (async () => {

                  setLoading(true)

                  // Configure data request
                  const params = {
                        brand: "all",
                        table: "policies",
                        query: {
                              select: "created, ref, legal_entity_name, payment_type, opayo_ref, cbpf_ref, acturis_contact_ref, acturis_version_ref, principal_id, gross_premium, initial_payment ",
                              where: { [ `docs_issued>='${startDate} 00:00:00'` ]: "", [ `docs_issued<='${endDate} 23:59:59'` ]: "" },
                              order: { created: "DESC" }
                        }
                  }

                  // Request data
                  const data = await get(params, AppData)

                  // Handle error
                  if (data.status !== "success") { message.error("Something went wrong"); return }

                  // Format some data prior to sending to table
                  for (let r of data.result) {
                        r.created = dayjs(r.created).format("HH:mm DD MMM YYYY") // Form date
                        r.payment_type = r.payment_type.toLowerCase() === "f" ? "PAID" : "CBPF" // Clean payment type
                        r.balance = r.gross_premium !== null && r.initial_payment !== null ? Number(r.gross_premium - r.initial_payment).toFixed(2) : 0 // Dynamic balance
                  }

                  // Set table data
                  setData(data.result)


                  setLoading(false)

            })()

            // eslint-disable-next-line
      }, [ startDate, endDate ])

      const columns = [
            {
                  name: 'Purchased',
                  key: 'created'
            },
            {
                  name: 'Policy Number',
                  key: 'ref'
            },
            {
                  name: 'Insured Entity',
                  key: 'legal_entity_name'
            },
            {
                  name: 'Payment Method',
                  key: 'payment_type'
            },
            {
                  name: 'Opayo Ref',
                  key: 'opayo_ref'
            },
            {
                  name: 'CBPF Ref',
                  key: 'cbpf_ref'
            },
            {
                  name: 'Acturis Contact',
                  key: 'acturis_contact_ref'
            },
            {
                  name: 'Acturis Version',
                  key: 'acturis_version_ref'
            },
            {
                  name: 'Provego Ref',
                  key: 'principal_id'
            },
            {
                  name: 'Premium',
                  key: 'gross_premium'
            },
            {
                  name: 'Paid',
                  key: 'initial_payment'
            },
            {
                  name: 'Balance',
                  key: 'balance'
            }
      ]

      const customTableConfig = {
            columns,
            search: {
                  active: true
            },
            export: {
                  active: true
            }
      }

      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={21}>

                                    <Row className="pagetitle">

                                          <Col>
                                                <h1>Report : Principal & Payment Reconciliation</h1>
                                                <p>Designed to help Shane match up paid transactions & settle with Provego</p>
                                          </Col>

                                    </Row>

                                    <div className="card" >

                                          {loading
                                                ? <Skeleton active />
                                                : <Table
                                                      data={data}
                                                      config={{ ...defaultTableConfig, ...customTableConfig }}
                                                />
                                          }

                                    </div>

                              </Col>

                        </Row>

                  </div>

            </>

      )

}
