import { Row, Col, Badge } from "antd"
import { Header } from "../Header"
import { useEffect, useContext, useState } from 'react'
import { ActurisAllErrorsTable } from "../ActurisAllErrorsTable"
import { MyContext } from '../../App'
import axios from "axios"

export function Acturis() {

      const { AppData } = useContext(MyContext)
      const [ allErrors, setAllErrors ] = useState([])

      // Gets all unhandled Acturis errors
      useEffect(() => {

            (async () => {
                  const { data } = await axios.get(`${AppData.api.base}/policies/acturis/errors`)
                  console.log(data)
                  setAllErrors(data.result)
            })()

            // eslint-disable-next-line
      }, [])



      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={22}>

                                    <Row className="pagetitle">
                                          <Col>
                                                <h1>Acturis Errors</h1>
                                                <p>“Failure is like a caterpillar before it becomes a butterfly”</p>
                                          </Col>
                                    </Row>

                                    {/* <div className="card" >
                                          <h2>New Business - API Errors <Badge color={newBusErrors.length > 0 ? "#FF4D4F" : "#00813F"} style={{ marginLeft: "10px" }} overflowCount={99} count={newBusErrors.length} showZero /></h2>
                                          {newBusErrors.length > 0 ? <ActurisNewBusinessErrorsTable data={newBusErrors} /> : null}
                                    </div> */}

                                    <div className="card" >
                                          <h2>All Business Events - API Errors <Badge color={allErrors.length > 0 ? "#FF4D4F" : "#00813F"} style={{ marginLeft: "10px" }} overflowCount={999} count={allErrors.length} showZero /></h2>
                                          {allErrors.length > 0 ? <ActurisAllErrorsTable data={allErrors} /> : null}
                                    </div>

                              </Col>

                        </Row>

                  </div>

            </>

      )

}
