import { Row, Col } from "antd"
import { Header } from "../Header"
import { IncompleteTable } from "../IncompleteTable"

export function Incomplete() {


      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={22}>

                                    <Row className="pagetitle">
                                          <Col>
                                                <h1>Incomplete Policies</h1>
                                                <p>Be careful. Check payment status manually. There will be duplicates in here ..</p>
                                          </Col>
                                    </Row>

                                    <div className="card" >
                                          <IncompleteTable />
                                    </div>

                              </Col>

                        </Row>

                  </div>

            </>

      )

}
