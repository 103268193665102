import { update, get } from "../libs/helper"
import { MyContext } from '../App'
import { useContext, useState, useEffect } from "react"
import { Upload, Form, Input, Button, message, Row, Col, Skeleton, Popconfirm } from 'antd'
import { UploadOutlined, DownloadOutlined, SendOutlined } from '@ant-design/icons'
import S3FileUpload from 'react-s3'
import provego_logo from '../images/provego.png'
const { Dragger } = Upload
const props = {
      name: 'file',
      multiple: false,
      action: null,
      onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files)
      },
}
window.Buffer = window.Buffer || require("buffer").Buffer

export function Documents({ id }) {

      const { AppData } = useContext(MyContext)

      const [ isLoading, setIsLoading ] = useState(true)
      const [ isUpdating, setIsUpdating ] = useState(false)
      const [ isComplete, setIsComplete ] = useState(false)
      const [ principalID, setPrincipalID ] = useState('')
      const [ isEl, setIsEl ] = useState(false)
      const [ pvgLoading, setPvgLoading ] = useState(false)

      const config = {
            bucketName: 'provego-policy-docs',
            dirName: `${id}`,
            region: 'eu-west-2',
            accessKeyId: 'AKIA2UM7VCLMYDIF5YWU',
            secretAccessKey: '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',
      }

      useEffect(() => {

            (async () => { await getPrincipal() })()

            // eslint-disable-next-line
      }, [ id ])

      const getPrincipal = async () => {

            setIsLoading(true)

            let data = {
                  brand: id.includes("BG") ? "boxguard" : "fastquote",
                  table: 'policies',
                  query: {
                        select: 'principal_id, principal_docs_issued, el',
                        where: { policy_number: id },
                  }
            }

            let p = await get(data, AppData)
            p = p.result
            setIsLoading(false)

            if (p[ 0 ].principal_id !== null) {
                  setPrincipalID(p[ 0 ].principal_id)
                  setIsComplete(true)
                  if (p[ 0 ].el === "10") { setIsEl(true) }
            }

      }

      async function uploadS3(file) {

            return new Promise((resolve, reject) => {

                  S3FileUpload.uploadFile(file, config)
                        .then(data => {
                              console.log('success', data)
                              resolve(true)
                        }
                        )
                        .catch(err => {
                              console.log('error', err)
                              reject(err)
                        })

            })

      }

      const getFile = (e) => {
            if (Array.isArray(e)) {
                  return e
            }
            return e && e.fileList
      }

      function renameFile(originalFile, newName) {
            return new File([ originalFile ], newName, {
                  type: originalFile.type,
                  lastModified: originalFile.lastModified,
            })
      }

      async function onFinish(v) {

            setIsUpdating(true)

            const PRINCIPAL_ID = v.id.trim().toUpperCase()

            console.log('Provego ID', PRINCIPAL_ID)

            let schedule = renameFile(v.schedule[ 0 ].originFileObj, 'schedule.pdf')
            let schedule_result = await uploadS3(schedule)

            let statement = renameFile(v.statement[ 0 ].originFileObj, 'statement.pdf')
            let statement_result = await uploadS3(statement)

            if (v.el !== undefined) {
                  let el_cert = renameFile(v.el[ 0 ].originFileObj, 'employers-certificate.pdf')
                  let el_cert_result = await uploadS3(el_cert)
                  console.log(el_cert_result)
            }

            setIsUpdating(false)

            if (schedule_result && statement_result) {

                  const data = {
                        brand: id.includes("BG") ? "boxguard" : "fastquote",
                        table: 'policies',
                        data: {
                              where: { policy_number: id },
                              data: { principal_id: PRINCIPAL_ID }
                        }
                  }

                  console.log(JSON.stringify(data))

                  // Update policy record with PVG reference
                  let pupdate = await update(data, AppData)
                  console.log(pupdate)

                  // Handle user error
                  if (pupdate.status === "success") {
                        message.success(`${PRINCIPAL_ID} documents successfully uploaded`)
                        await getPrincipal()

                  } else {
                        message.error(`${PRINCIPAL_ID} didn't upload correct. Try again.`)
                  }

            }



      }


      async function reissueDocs(id) {

            setPvgLoading(true)

            const brand = id.includes("FQ") ? "fastquote" : "boxguard"

            const d = {
                  brand,
                  table: "policies",
                  data: {
                        where: { policy_number: id },
                        data: { principal_docs_issued: null }
                  }
            }

            let result = await update(d, AppData)

            setPvgLoading(false)

            // Handle response
            if (result.status === "success") {
                  message.success("Documents will be re-issued within 2-3 minutes")
            }
            else { message.error(result.result) }
      }


      const Principal = isComplete

            ? <>

                  <img src={provego_logo} style={{ width: 125, display: "block", marginBottom: "25px" }} alt="Provego logo" />
                  <p>{principalID}</p>

                  <a href={`https://provego-policy-docs.s3.eu-west-2.amazonaws.com/${id}/schedule.pdf`} target="_blank" rel="noreferrer">
                        <Button className="btn-xs" style={{ marginTop: "5px" }} type="primary" icon={<DownloadOutlined />}>
                              View Schedule
                        </Button>
                  </a>

                  <a href={`https://provego-policy-docs.s3.eu-west-2.amazonaws.com/${id}/statement.pdf`} target="_blank" rel="noreferrer">
                        <Button className="btn-xs" style={{ marginLeft: "5px", marginTop: "5px" }} type="primary" icon={<DownloadOutlined />}>
                              View SOF
                        </Button>
                  </a>

                  {isEl ? <a href={`https://provego-policy-docs.s3.eu-west-2.amazonaws.com/${id}/employers-certificate.pdf`} target="_blank" rel="noreferrer"><Button className="btn-xs" style={{ marginLeft: "5px", marginTop: "5px" }} type="primary" icon={<DownloadOutlined />}>
                        View EL Certificate
                  </Button></a> : null}

                  <Popconfirm
                        title="Re-issue Provego Documents"
                        description="Are you sure you want to re-issue to client?"
                        onConfirm={() => reissueDocs(id)}
                        okText="Yes"
                        cancelText="No"
                  >
                        <Button loading={pvgLoading} className="btn-xs" style={{ marginLeft: "5px", verticalAlign: "-1px" }} type="dashed" icon={<SendOutlined />}></Button>
                  </Popconfirm>

            </>


            : <>

                  <Form
                        style={{ width: "100%", marginTop: "15px" }}
                        name="event"
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="vertical"
                  >
                        <Form.Item
                              label="Policy Number eg. PVG122873795"
                              name="id"
                              rules={[
                                    {
                                          required: true,
                                          pattern: new RegExp(/PVG/),
                                          message: "Please provide Provego's 'PVG' policy number",
                                    },
                              ]}
                              style={{ width: "500px" }}
                        >
                              <Input />
                        </Form.Item>



                        <Form.Item
                              name="schedule"
                              getValueFromEvent={getFile}
                              rules={[
                                    {
                                          required: true,
                                          message: "Upload Provego Schedule",
                                    },
                              ]}
                        >
                              <Dragger {...props} style={{ width: "500px" }} beforeUpload={() => false}>
                                    <Row>
                                          <Col span={5}><p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}><UploadOutlined style={{ width: "45px", color: "rgba(98, 195, 208, 1)" }} /></p></Col>
                                          <Col offset={1}>
                                                <p className="ant-upload-text">Provego : Policy Schedule</p>
                                                <p className="ant-upload-hint" style={{ textAlign: "left", marginTop: "-5px", marginBottom: 0 }}>Drag or click to upload</p>
                                          </Col>
                                    </Row>
                              </Dragger>
                        </Form.Item>

                        <Form.Item
                              name="statement"
                              getValueFromEvent={getFile}
                              rules={[
                                    {
                                          required: true,
                                          message: "Upload Provego Statement of Facts",
                                    },
                              ]}
                        >
                              <Dragger {...props} style={{ width: "500px" }} beforeUpload={() => false}>
                                    <Row>
                                          <Col span={5}><p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}><UploadOutlined style={{ width: "45px", color: "rgba(98, 195, 208, 1)" }} /></p></Col>
                                          <Col offset={1}>
                                                <p className="ant-upload-text">Provego : Statement of Facts</p>
                                                <p className="ant-upload-hint" style={{ textAlign: "left", marginTop: "-5px", marginBottom: 0 }}>Drag or click to upload</p>
                                          </Col>
                                    </Row>
                              </Dragger>
                        </Form.Item>

                        <Form.Item
                              name="el"
                              getValueFromEvent={getFile}
                        >
                              <Dragger {...props} style={{ width: "500px" }} beforeUpload={() => false}>
                                    <Row>
                                          <Col span={5}><p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}><UploadOutlined style={{ width: "45px", color: "rgba(98, 195, 208, 1)" }} /></p></Col>
                                          <Col offset={1}>
                                                <p className="ant-upload-text">Optional : Employers Liability Certificate</p>
                                                <p className="ant-upload-hint" style={{ textAlign: "left", marginTop: "-5px", marginBottom: 0 }}>Drag or click to upload</p>
                                          </Col>
                                    </Row>
                              </Dragger>
                        </Form.Item>

                        <Form.Item
                        >
                              <Button loading={isUpdating} className="btn-xs" type="primary" htmlType="submit" style={{ marginTop: "5px" }}>
                                    Update Policy Record
                              </Button>
                        </Form.Item>
                  </Form >

            </>

      if (isLoading) {

            return (

                  <Skeleton active />

            )

      }

      else {

            return (

                  <>
                        <h2>Principal Documents</h2>
                        <p style={{ marginTop: "-15px" }}>These are the documents we send when our Principal confirms Schedule & SOF</p>
                        {Principal}
                  </>


            )

      }


}

