import HashLoader from "react-spinners/HashLoader"


export function Loading({ size = 50 }) {

      return (
            <HashLoader
                  color="#E41882"
                  loading={true}
                  // cssOverride={override}
                  size={size}
                  aria-label="Loading Spinner"
                  data-testid="loader"
            />
      )

}