import { Row, Col, Tooltip } from "antd"
import { useEffect, useState, useContext } from "react"
import { MyContext } from '../App'
import { get } from "../libs/helper"

import dayjs from "dayjs"
var isoWeek = require('dayjs/plugin/isoWeek')
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isoWeek)
dayjs.extend(isSameOrAfter)

export function DashAdmin() {

      const { AppData } = useContext(MyContext)

      // Establish dates
      const startWeek = dayjs().startOf('isoWeek').format("YYYY-MM-DD")
      const startMonth = dayjs().startOf('month').format("YYYY-MM-DD")

      // Define earliest point for Dashboard data
      const startDate = dayjs(startWeek).isBefore(startMonth, 'day') ? startWeek : startMonth

      const [ data, setData ] = useState([])

      useEffect(() => {

            (async () => {

                  // Configure data request
                  const params = {
                        brand: "all",
                        table: "policies",
                        query: {
                              select: `IF(policy_number LIKE '%FQ%', "FQ", "BG") as brand, DATE(docs_issued) as date, SUM(revenue) as revenue, COUNT(id) as tx`,
                              where: {
                                    [ `docs_issued >= '${startDate} 00:00:00'` ]: ""
                              },
                              order: { 'DATE(docs_issued)': "DESC" },
                              group: 'DATE(docs_issued)'
                        }
                  }

                  // Request data
                  const { result } = await get(params, AppData)
                  setData(result)

            })()

      }, [ AppData, startDate ])

      // Dashboard 'to date' metrics
      let [ dtd, setDtd ] = useState('loading ..')
      let [ dtd2, setDtd2 ] = useState('loading ..')
      let [ wtd, setWtd ] = useState('loading ..')
      let [ wtd2, setWtd2 ] = useState('loading ..')
      let [ mtd, setMtd ] = useState('loading ..')
      let [ mtd2, setMtd2 ] = useState('loading ..')

      // Dynamic brand revenue splits
      let [ todaywidthfq, setTodayWidthFq ] = useState(50)
      let [ todaywidthbg, setTodayWidthBg ] = useState(50)
      let [ weekwidthfq, setWeekWidthFq ] = useState(50)
      let [ weekwidthbg, setWeekWidthBg ] = useState(50)
      let [ monthwidthfq, setMonthWidthFq ] = useState(50)
      let [ monthwidthbg, setMonthWidthBg ] = useState(50)

      // Handle today
      useEffect(() => {

            if (data.length === 0) return

            // Calculations for daily metrics
            let today = data.filter((d) => dayjs(d.date).isSame(dayjs(), 'day'))
            let [ today_rev, today_fq, today_bg ] = [ 0, 0, 0 ]
            today.map((d) => {
                  today_rev += +d.revenue
                  if (d.brand === "FQ") { today_fq = today_fq + d.tx } else { today_bg = today_bg + d.tx }
                  return true
            })
            setDtd(`£${new Intl.NumberFormat().format(today_rev.toFixed(0))} revenue on ${today_fq + today_bg} policies`)
            setDtd2(`Avg. policy earned £${new Intl.NumberFormat().format((today_rev / (today_fq + today_bg)).toFixed(0))}`)
            setTodayWidthFq((today_fq / (today_fq + today_bg)) * 100)
            setTodayWidthBg((today_bg / (today_fq + today_bg)) * 100)


      }, [ data ])

      // Handle isoWeek
      useEffect(() => {

            if (data.length === 0) return

            // Calculations for weekly metrics
            let week = data.filter((d) => dayjs(d.date).isSameOrAfter(dayjs().startOf('isoWeek')))
            let [ week_rev, week_fq, week_bg ] = [ 0, 0, 0 ]
            week.map((d) => {
                  week_rev += +d.revenue
                  if (d.brand === "FQ") { week_fq = week_fq + d.tx } else { week_bg = week_bg + d.tx }
                  return true
            })
            setWtd(`£${new Intl.NumberFormat().format(week_rev.toFixed(0))} revenue on ${week_fq + week_bg} policies`)
            setWtd2(`Avg. policy earned £${new Intl.NumberFormat().format((week_rev / (week_fq + week_bg)).toFixed(0))}`)
            setWeekWidthFq((week_fq / (week_fq + week_bg)) * 100)
            setWeekWidthBg((week_bg / (week_fq + week_bg)) * 100)


      }, [ data ])

      // Handle month
      useEffect(() => {

            if (data.length === 0) return

            // Calculations for monthly metrics
            let month = data.filter((d) => dayjs(d.date).isSameOrAfter(dayjs().startOf('month')))
            let [ month_rev, month_fq, month_bg ] = [ 0, 0, 0 ]
            month.map((d) => {
                  month_rev += +d.revenue
                  if (d.brand === "FQ") { month_fq = month_fq + d.tx } else { month_bg = month_bg + d.tx }
                  return true
            })
            setMtd(`£${new Intl.NumberFormat().format(month_rev.toFixed(0))} revenue on ${month_fq + month_bg} policies`)
            setMtd2(`End of month forecast ~£${new Intl.NumberFormat().format(((month_rev / dayjs().format("D")) * dayjs().daysInMonth()).toFixed(0))}`)
            setMonthWidthFq((month_fq / (month_fq + month_bg)) * 100)
            setMonthWidthBg((month_bg / (month_fq + month_bg)) * 100)


      }, [ data ])


      return (

            <>

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col xs={24} md={22}>

                                    <Row gutter={25}>

                                          <Col className="gutter-row" span={24}>

                                                <Row className="pagetitle">
                                                      <Col>
                                                            <h1>Performance Overview</h1>
                                                            <p>Blended data from across our brands</p>
                                                      </Col>
                                                </Row>

                                                <div id="home-content">

                                                      {/* Metrics */}
                                                      <Row gutter={25}>

                                                            <Col className="gutter-row" xs={24} lg={8}>
                                                                  <div className="card" >
                                                                        <h2>Today so far ..</h2>
                                                                        <p style={{ fontWeight: 500, fontSize: '140%' }} >{dtd}</p>
                                                                        <p style={{ marginTop: '-8px', fontWeight: 500, opacity: 0.3 }}>{dtd2}</p>
                                                                        <Row className="split-bar-wrapper">
                                                                              <Tooltip title={`FQ: ${todaywidthfq.toFixed(0)}%`}>
                                                                                    <Col className="split-bar-section" style={{ background: '#BED1FB', width: `${todaywidthfq}%` }}>
                                                                                    </Col>
                                                                              </Tooltip>
                                                                              <Tooltip title={`BG: ${todaywidthbg.toFixed(0)}%`}>
                                                                                    <Col className="split-bar-section" style={{ background: '#C6EFD6', width: `${todaywidthbg}%` }}>
                                                                                    </Col>
                                                                              </Tooltip>
                                                                        </Row>
                                                                  </div>
                                                            </Col>

                                                            <Col className="gutter-row" xs={24} lg={8}>
                                                                  <div className="card" >
                                                                        <h2>Week #{dayjs().format("W")} so far</h2>
                                                                        <p style={{ fontWeight: 500, fontSize: '140%' }} >{wtd}</p>
                                                                        <p style={{ marginTop: '-8px', fontWeight: 500, opacity: 0.3 }}>{wtd2}</p>
                                                                        <Row className="split-bar-wrapper">
                                                                              <Tooltip title={`FQ: ${weekwidthfq.toFixed(0)}%`}>
                                                                                    <Col className="split-bar-section" style={{ background: '#BED1FB', width: `${weekwidthfq}%` }}>
                                                                                    </Col>
                                                                              </Tooltip>
                                                                              <Tooltip title={`BG: ${weekwidthbg.toFixed(0)}%`}>
                                                                                    <Col className="split-bar-section" style={{ background: '#C6EFD6', width: `${weekwidthbg}%` }}>
                                                                                    </Col>
                                                                              </Tooltip>
                                                                        </Row>
                                                                  </div>
                                                            </Col>

                                                            <Col className="gutter-row" xs={24} lg={8}>
                                                                  <div className="card" >
                                                                        <h2>{dayjs().format("MMMM")} so far ..</h2>
                                                                        <p style={{ fontWeight: 500, fontSize: '140%' }} >{mtd}</p>
                                                                        <p style={{ marginTop: '-8px', fontWeight: 500, opacity: 0.3 }}>{mtd2}</p>
                                                                        <Row className="split-bar-wrapper">
                                                                              <Tooltip title={`FQ: ${monthwidthfq.toFixed(0)}%`}>
                                                                                    <Col className="split-bar-section" style={{ background: '#BED1FB', width: `${monthwidthfq}%` }}>
                                                                                    </Col>
                                                                              </Tooltip>
                                                                              <Tooltip title={`BG: ${monthwidthbg.toFixed(0)}%`}>
                                                                                    <Col className="split-bar-section" style={{ background: '#C6EFD6', width: `${monthwidthbg}%` }}>
                                                                                    </Col>
                                                                              </Tooltip>
                                                                        </Row>
                                                                  </div>
                                                            </Col>

                                                      </Row>

                                                </div>

                                          </Col>
                                    </Row>


                              </Col>


                        </Row>

                  </div >
            </>

      )


}
