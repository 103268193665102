export const config = {
      stage: {
            api: {
                  base: 'https://stage-api.dasher.uk'
            },
            reports: {
                  default_period: 30,
                  default_period_type: 'd'
            }
      },
      prod: {
            api: {
                  base: 'https://api.dasher.uk'
            },
            reports: {
                  default_period: 30,
                  default_period_type: 'd'
            }
      }
}

export const userConfig = {
      viewer: {
            level: 0,
      },
      editor: {
            level: 1,
      },
      manager: {
            level: 2,
      },
      admin: {
            level: 3,
      },
      deity: {
            level: 4,
      }
}

export const defaultTableConfig = {
      search: {
            active: true,
            keys: 'all'
      },
      export: {
            active: true,
            filename: 'report'
      },
      datepicker: {
            active: true,
            period: 30,
            period_type: 'd'
      },
      pagination: {
            defaultPageSize: 25,
            showSizeChanger: true,
            pageSizeOptions: [ '25', '50', '100', '500', '1000', '10000' ]
      },
      onRow: {
            active: false
      }
}