import { get } from "../libs/helper"
import dayjs from "dayjs"
import { Timeline, Tag, Row, Col, message } from 'antd'
import { useEffect, useState, useContext } from "react"
import { MyContext } from '../App'
import { EventForm } from './EventForm'

export function EventTimeline({ id }) {

      const { AppData } = useContext(MyContext)
      const [ isLoading, setIsLoading ] = useState(true)
      const [ events, setEvents ] = useState([])

      useEffect(() => {

            (async () => { await getEvents() })()

            // eslint-disable-next-line
      }, [ id ])

      const getEvents = async () => {

            let params = {
                  brand: id.includes("BG") ? "boxguard" : "fastquote",
                  table: 'log_events',
                  query: {
                        where: { relation: id },
                        order: { created: 'DESC' }
                  }
            }

            const data = await get(params, AppData)
            data.status === "success" ? setEvents(data.result) : message.error("Something went wrong")
            setIsLoading(false)

      }

      return (

            <>

                  <Row>

                        <Col span={15}>

                              {!isLoading

                                    ?
                                    <>
                                          <div id="event-timeline">

                                                <Timeline>
                                                      {
                                                            events.map((item, index) => {
                                                                  const datetime = <Tag>{dayjs(item.created).format("HH:mm Do MMM")}</Tag>
                                                                  const userObj = AppData.users.filter(x => x.id === item.user)
                                                                  const user = <Tag>{`${userObj[ 0 ].first_name} ${userObj[ 0 ].last_name}`}</Tag>
                                                                  const content = item.data
                                                                  const color = "#62C3D0"
                                                                  return (
                                                                        <Timeline.Item key={index} color={color}>{datetime}{user}{unescape(content)}</Timeline.Item>
                                                                  )
                                                            })
                                                      }
                                                </Timeline>

                                          </div >

                                    </>

                                    : <p>loading ..</p>
                              }

                        </Col>


                        <Col span={6} offset={2}>

                              <EventForm id={id} updateParent={getEvents} />

                        </Col>

                  </Row>

            </>

      )
}

