import { Row, Col } from "antd"
import { Header } from "../Header"

export function Error() {

      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={21}>

                                    <Row className="pagetitle">
                                          <Col>
                                                <h1>Whoops, something went wrong.</h1>
                                                <p>The page you request cannot be found</p>
                                          </Col>
                                    </Row>

                              </Col>

                        </Row>

                  </div>


            </>



      )


}
