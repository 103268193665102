import { Row, Col, Skeleton, message, Tag } from "antd"
import { Header } from "../../Header"
import { Table } from "../../tables/Table"
import { useEffect, useState, useContext } from "react"
import { Chart } from "react-google-charts"
import dayjs from "dayjs"
import { MyContext } from "../../../App"
import { defaultTableConfig } from "../../../libs/config"
import { get } from "../../../libs/helper"

export function Tickets() {

      const { AppData, UserData } = useContext(MyContext)
      const [ startDate, setStartDate ] = useState(dayjs().subtract(AppData.reports.default_period, AppData.reports.default_period_type).format("YYYY-MM-DD"))
      const [ endDate, setEndDate ] = useState(dayjs().format("YYYY-MM-DD"))
      const [ loading, setLoading ] = useState(true)
      const [ data, setData ] = useState([])
      const [ chartData, setChartData ] = useState([])



      useEffect(() => {

            if (data.length > 0) {

                  const lookBack = 30
                  let i = lookBack

                  let tempArr = [ [ 'Type', 'MTA', 'Canx', 'Copy Docs', 'Other' ] ]

                  for (let d of data) {

                        const period = dayjs().subtract(i, 'day')
                        const periodData = data.filter((d) => dayjs(d.created).isSame(period, 'day'))

                        const mta = periodData.filter((d) => d.enquiry_type === "mta")
                        const canx = periodData.filter((d) => d.enquiry_type === "canx")
                        const copy = periodData.filter((d) => d.enquiry_type === "copy_docs")
                        const other = periodData.filter((d) => d.enquiry_type === "other")

                        const item = [ dayjs(period).format("DD MMM"), mta.length, canx.length, copy.length, other.length ]
                        tempArr.push(item)

                        i--
                        if (i < 0) break
                  }

                  setChartData(tempArr)
            }

      }, [ data ])

      useEffect(() => {

            if (UserData?.report_date_range) {
                  setStartDate(UserData.report_date_range[ 0 ])
                  setEndDate(UserData.report_date_range[ 1 ])
            }

      }, [ UserData.report_date_range ])

      useEffect(() => {

            (async () => {

                  setLoading(true)

                  // Configure data request
                  const params = {
                        brand: "dasher",
                        table: "sofia",
                        query: {
                              where: {
                                    customer_type: "customer",
                                    [ `customer_type = "customer" AND created>='${startDate} 00:00:00'` ]: "",
                                    [ `created<='${endDate} 23:59:59'` ]: ""
                              },
                              order: { created: "DESC" }
                        }
                  }

                  // Request data
                  const data = await get(params, AppData)

                  console.log(data)

                  // Handle error
                  if (data.status !== "success") { message.error("Something went wrong"); return }

                  // Format some data prior to sending to table
                  for (let r of data.result) {
                        r.created = dayjs(r.created).format("HH:mm DD MMM YYYY") // format date
                        // r.payment_type = r.payment_type.toLowerCase() === "f" ? "PAID" : "CBPF" // clean payment type
                  }

                  // Set table data
                  setData(data.result)

                  setLoading(false)

            })()

            // eslint-disable-next-line
      }, [ startDate, endDate ])

      function isJsonString(str) {
            try {
                  JSON.parse(str)
            } catch (e) {
                  return false
            }
            return true
      }

      const columns = [
            {
                  name: 'Created',
                  key: 'created'
            },
            {
                  name: 'Context',
                  key: 'id',
                  render: (item, record) => {

                        const brand = record.brand === "fastquote" ? "FQ" : "BG"
                        const color = record.brand === "fastquote" ? "blue" : "green"

                        return (
                              <>
                                    <Tag color={color}>{brand}</Tag>
                                    <Tag>{record.enquiry_type.toUpperCase()}</Tag>
                              </>
                        )

                  }
            },
            {
                  name: 'Ticket',
                  key: 'data',
                  width: "30%",
                  render: (item, record) => {

                        // console.log(item)
                        // const nn = JSON.parse(JSON.stringify(item))
                        // console.log(nn)

                        return (
                              <>{item}</>

                        )

                  },

            }

      ]

      const customTableConfig = {
            key: "id",
            columns,
            search: {
                  active: true
            },
            export: {
                  active: true
            }
      }

      const options = {
            isStacked: true,
            legend: "none"
      }

      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={21}>

                                    <Row className="pagetitle">

                                          <Col>
                                                <h1>Just the ticket ..</h1>
                                                <p>Somewhere only we know. Because we're Keane.</p>
                                          </Col>

                                    </Row>

                                    <div className="card" >
                                          {data.length < 1
                                                ? <Skeleton active />
                                                : <Chart
                                                      chartType="ColumnChart"
                                                      data={chartData}
                                                      options={options}
                                                      style={{ width: "100%", height: "250px", backgroundColor: "transparent" }}
                                                      // height="250"
                                                      legend={false}
                                                />
                                          }
                                    </div>

                                    <div className="card" >
                                          {loading
                                                ? <Skeleton active />
                                                : <Table
                                                      data={data}
                                                      config={{ ...defaultTableConfig, ...customTableConfig }}
                                                />
                                          }
                                    </div>

                              </Col>

                        </Row>

                  </div>

            </>

      )

}
