import axios from "axios"

export async function get(d, AppData) {
      return axios.post(`${AppData.api.base}/read/${d.brand}/${d.table}`, d.query).then(data => { return data.data })
}

export async function save(d, AppData) {
      return axios.post(`${AppData.api.base}/create/${d.brand}/${d.table}`, d.data).then(data => { return data.data })
}

export async function update(d, AppData) {
      return axios.post(`${AppData.api.base}/update/${d.brand}/${d.table}`, d.data).then(data => { return data.data })
}

export function round(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

export function ucFirst(str) {
      str = str.toLowerCase()
      str = str.charAt(0).toUpperCase() + str.slice(1)

      return str
}

export const unescapeData = async (data) => {
      if (data.length > 0) {
            for await (let d of data) {
                  Object.keys(d).forEach((key) => {
                        let v = d[ key ]
                        if (v && v.length > 0) {
                              if (typeof v === "string") {
                                    d[ key ] = unescape(v)
                              }
                        }
                  })
            }
      } else {
            Object.keys(data).forEach((key) => {
                  let v = data[ key ]
                  if (v && v.length > 0) {
                        if (typeof v === "string") {
                              data[ key ] = unescape(v)
                        }
                  }
            })
      }
      return data
}

export const debounce = (func, delay) => {
      let debounceTimer
      return function () {
            const context = this
            const args = arguments
            clearTimeout(debounceTimer)
            debounceTimer = setTimeout(() => func.apply(context, args), delay)
      }
}

export function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}