import { config } from "./libs/config"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useEffect, useReducer, createContext } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Home } from "./components/pages/Home"
import { AccountingReconciliation } from "./components/pages/reports/AccountingReconciliation"
import { OpayoReconciliation } from "./components/pages/reports/OpayoReconciliation"
import { PerformanceAnalysis } from "./components/pages/reports/PerformanceAnalysis"
import { Acturis } from "./components/pages/Acturis"
import { Customers } from "./components/pages/reports/Customers"
import { Tickets } from "./components/pages/reports/Tickets"
import { Incomplete } from "./components/pages/Incomplete"
import { View } from "./components/pages/View"
import { Login } from "./components/pages/Login"
import { Error } from "./components/pages/Error"
import dayjs from "dayjs"

const env = window.location.hostname.includes("stage") || window.location.hostname.includes("local") ? 'stage' : 'prod'

export const MyContext = createContext()

export default function App() {

      const [ AppData, setAppData ] = useReducer((oldData, newItem) => { return ({ ...oldData, ...newItem }) }, { version: "ch@rl3s v47", status: "", ...config[ env ] })
      const [ UserData, setUserData ] = useReducer((oldData, newItem) => { return ({ ...oldData, ...newItem }) }, { loggedIn: false })

      // Logs AppData changes
      useEffect(() => {
            // console.log(`AppData ${dayjs().format("HH:mm:ss.SSS")}`, JSON.stringify(AppData))
      }, [ AppData ])

      // Logs UserData changes
      useEffect(() => {
            // console.log(`UserData ${dayjs().format("HH:mm:ss.SSS")}`, JSON.stringify(UserData))
      }, [ UserData ])

      if (UserData.loggedIn) {

            return (

                  <ErrorBoundary FallbackComponent={<Error />}>
                        <MyContext.Provider value={{ AppData, setAppData, UserData, setUserData }}>
                              <Router>
                                    <Routes>
                                          <Route path="/" element={<Home />} />
                                          <Route path="/reports/accounting/reconciliation" element={<AccountingReconciliation />} />
                                          <Route path="/reports/accounting/opayo" element={<OpayoReconciliation />} />
                                          <Route path="/reports/sales/performance-analysis" element={<PerformanceAnalysis />} />
                                          <Route path="/reports/acturis" element={<Acturis />} />
                                          <Route path="/reports/customers" element={<Customers />} />
                                          <Route path="/reports/tickets" element={<Tickets />} />
                                          <Route path="/reports/customers/incomplete" element={<Incomplete />} />
                                          <Route path="/customer/view" element={<View />} />
                                          <Route path="/*" element={<Error />} />
                                    </Routes>
                              </Router>
                        </MyContext.Provider>
                  </ErrorBoundary>
            )

      } else {

            return (
                  <MyContext.Provider value={{ AppData, setAppData, setUserData }}>
                        <Router>
                              <Routes>
                                    <Route path="*" element={<Login />} />
                              </Routes>
                        </Router>
                  </MyContext.Provider>
            )

      }

}
