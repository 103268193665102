import { useContext } from "react"
import { save } from "../libs/helper"
import { Button, Form, Input, message } from 'antd'
import { v4 as uuid } from "uuid"
import { MyContext } from '../App'
const { TextArea } = Input


export function EventForm({ id, updateParent }) {

      const { AppData, UserData } = useContext(MyContext)

      const saveEvent = async (values) => {

            let data = {
                  brand: id.includes("BG") ? "boxguard" : "fastquote",
                  table: 'log_events',
                  data: {
                        id: uuid(),
                        type: "note",
                        relation: id,
                        user: UserData.id,
                        data: escape(values.content)
                  }
            }

            const saveEvent = await save(data, AppData)

            message[ saveEvent.status ](saveEvent.result)

            updateParent()

      }

      return (

            <>

                  Add new manual event/note ..

                  <Form
                        style={{ width: "100%", marginTop: "15px" }}
                        name="event"
                        onFinish={saveEvent}
                        autoComplete="off"
                  >
                        <Form.Item
                              name="content"
                              rules={[
                                    {
                                          required: true,
                                          message: 'Please provide some note ..',
                                    },
                              ]}
                        >
                              <TextArea rows={3} />
                        </Form.Item>

                        <Form.Item
                        >
                              <Button className="btn-xs" type="primary" htmlType="submit">
                                    Create Note
                              </Button>
                        </Form.Item>
                  </Form>

            </>

      )
}

