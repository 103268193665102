import { Row, Col, Skeleton, message, Tag } from "antd"
import { Header } from "../../Header"
import { useNavigate } from 'react-router-dom'
import { Table } from "../../tables/Table"
import { useEffect, useState, useContext } from "react"
import dayjs from "dayjs"
import { MyContext } from "../../../App"
import { defaultTableConfig } from "../../../libs/config"
import { get } from "../../../libs/helper"

export function Customers() {

      const navigate = useNavigate()

      const { AppData, UserData } = useContext(MyContext)
      const [ startDate, setStartDate ] = useState(dayjs().subtract(AppData.reports.default_period, AppData.reports.default_period_type).format("YYYY-MM-DD"))
      const [ endDate, setEndDate ] = useState(dayjs().format("YYYY-MM-DD"))
      const [ loading, setLoading ] = useState(true)
      const [ data, setData ] = useState([])

      useEffect(() => {

            if (UserData?.report_date_range) {
                  setStartDate(UserData.report_date_range[ 0 ])
                  setEndDate(UserData.report_date_range[ 1 ])
            }

      }, [ UserData.report_date_range ])

      useEffect(() => {

            (async () => {

                  setLoading(true)

                  // Configure data request
                  const params = {
                        brand: "all",
                        table: "policies",
                        query: {
                              select: "payment_completed, policy_number, legal_entity_name, payment_type, gross_premium, driver_type, client_login",
                              where: { [ `docs_issued>='${startDate} 00:00:00'` ]: "", [ `docs_issued<='${endDate} 23:59:59'` ]: "" },
                              order: { payment_completed: "DESC" }
                        }
                  }

                  // Request data
                  const data = await get(params, AppData)

                  // Handle error
                  if (data.status !== "success") { message.error("Something went wrong"); return }

                  // Format some data prior to sending to table
                  for (let r of data.result) {
                        r.payment_completed = dayjs(r.payment_completed).format("HH:mm DD MMM YYYY") // format date
                        r.payment_type = r.payment_type.toLowerCase() === "f" ? "PAID" : "CBPF" // clean payment type
                  }

                  // Set table data
                  setData(data.result)

                  setLoading(false)

            })()

            // eslint-disable-next-line
      }, [ startDate, endDate ])

      const columns = [
            {
                  name: 'Purchased',
                  key: 'payment_completed'
            },
            {
                  name: 'Policy Number',
                  key: 'policy_number'
            },
            {
                  name: 'Insured Entity',
                  key: 'legal_entity_name'
            },
            {
                  name: 'Information',
                  key: 'payment_type',
                  render: (item, record) => {

                        const driverType = record.driver_type.toLowerCase() === "c" ? "COURIER" : "REMOVER"

                        return (
                              <>
                                    <Tag>{driverType}</Tag>
                                    <Tag>{record.payment_type}</Tag>
                                    <Tag>LOGIN COUNT: {record.client_login}</Tag>
                              </>
                        )

                  }
            },
            {
                  name: 'Premium',
                  key: 'gross_premium'
            },

      ]

      const customTableConfig = {
            columns,
            search: {
                  active: true
            },
            export: {
                  active: true
            },
            onRow: {
                  active: true,
                  func: function (record) {
                        navigate(`/customer/view?id=${record.policy_number}`)
                  }

            }
      }

      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={21}>

                                    <Row className="pagetitle">

                                          <Col>
                                                <h1>Customers</h1>
                                                <p>Just a simple list of recent customer policies</p>
                                          </Col>

                                    </Row>

                                    <div className="card" >

                                          {loading
                                                ? <Skeleton active />
                                                : <Table
                                                      data={data}
                                                      config={{ ...defaultTableConfig, ...customTableConfig }}
                                                />
                                          }

                                    </div>

                              </Col>

                        </Row>

                  </div>

            </>

      )

}
